import "../css/activation.css"
import ActivationForm from "../ActivationForm/ActivationForm";
import { Col, Container, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import StartPopUp from "../StartPopUp/StartPopUp";
import { getMaintanancePopUpStatus } from "../../api/settings-landing"
import { getToken, checkToken } from "../../api/activation";
import PasswordProtection from "../PasswordProtection/PasswordProtection";
import Loader from "../features/loader";
import ActivationTutorialStep from "../features/ActivationTutorialStep";
import ActivareNavbar from "../features/Navbar";


function Activation({ type = null }) {
  const [loading, setLoading] = useState(true);
  const [startPopUpData, setStartPopUpData] = useState({
    title: "",
    content: "",
    isActive: "",
  });
  const [isProtected, setIsProtected] = useState(true);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');

  const getStartPopUpState = () => {
    setStartPopUpData({
      ...startPopUpData,
      isActive: !startPopUpData.isActive
    });
  }

  useEffect(() => {
    const fetchData = async () => {
      const result = await getMaintanancePopUpStatus();
      if (result.status === 200) {
        const settingsObject = result.data.message.reduce((acc, item) => {
          acc[item.name] = item.value;
          return acc;
        }, {});
        const parsedResultData = JSON.parse(settingsObject.popup_activare_microsoft_maintainance);
        if (parsedResultData.isActive == 1) {
          setStartPopUpData({
            title: parsedResultData.title,
            content: parsedResultData.content,
            isActive: parsedResultData.isActive,
          });
        }
      }
    };

    const checkIsProtected = async () => {
      const result = await checkToken()
      if (result?.status === 200) {
        setIsProtected(false);
      }
    }

    const initPage = async () => {
      await fetchData();
      await checkIsProtected();
      setLoading(false);
    }

    initPage();
  }, []);

  const handleCredentialsCheck = async (data) => {
    const result = await getToken(data);
    if (result?.status === 200 && result.data.error !== 1) {
      setShow(false);
      setMessage('');
      setIsProtected(false);
    } else {
      setMessage(result.data.message ?? result.data.error);
      setShow(true);
    }
  }

  if (loading) {
    return (
      <div style={{ height: "100vh", width: "100vw", backgroundColor: "#1B1E72" }}>
        <Loader variant="light" />
      </div>
    )
  }

  return (
    <>
      {isProtected ? (
        <>
          <StartPopUp startPopUpIsOpen={startPopUpData.isActive} getStartPopUpState={getStartPopUpState} modalData={startPopUpData} />
          <PasswordProtection
            handleSubmit={handleCredentialsCheck}
            show={show}
            message={message}
            type={type}
          />
        </>
      ) : (
        <>
          <ActivareNavbar />
          <Container fluid>
            <Row className="appBackground">
              <Col xl={12}>
                <Container className="appContainer">
                  <Row className="d-none d-md-block mt-4 w-100">
                    <Col xl={12} md={12} sm={12} xs={12} className="">
                      <img src="/assets/images/activare-header-img-2.svg" className="img-fluid w-100" alt="activation" />
                    </Col>
                  </Row>
                  <Container fluid className="mt-5 mb-5 text-center">
                    <Row className="wrapper align-items-center mb-3">
                      <Col className="titleActivate">
                        <h1>ACTIVAREA LICENȚEI OFFICE</h1>
                      </Col>
                    </Row>
                    <Row className="wrapper align-items-center mb-5 mt-5">
                      <Col md={12} className="">
                        <p className="fs-5 text-center">
                          Activarea licenței Office folosind activarea prin telefon nu a fost niciodată mai ușoară și rapidă! Folosind portalul nostru nu va trebui să contactați telefonic pe nimeni!
                        </p>
                        <p className="fs-5 text-center">
                          Descărcați și instalați programul de pe link-ul furnizat în email-ul comenzii dumneavoastră sau accesând următorul buton:
                          <a href="https://core-software.ro/descarcare-office/" target="_blank">
                            <button
                              style={{
                                backgroundColor: "#4E60FE",
                                color: "white",
                                border: "0",
                                cursor: "pointer",
                                borderRadius: "0.3rem",
                                padding: "0.1rem 0.3rem",
                                textAlign: "center",
                                marginLeft: "0.5rem"
                              }}
                            >
                              Click aici
                            </button>
                          </a>
                        </p>
                      </Col>
                    </Row>
                    <Row className="wrapper align-items-center">
                      <Col md={12} className="">
                        <Row className="mb-2" style={{ position: 'relative' }}>
                          <div className="hide-on-md" style={{ position: 'relative', width: '100%', textAlign: 'left', position: 'absolute', right: '3%', top: '50%', zIndex: '9999' }}>
                            <img src="/assets/images/CoreSoftware Group.svg" style={{ width: '3.5rem' }}></img>
                          </div>
                          <Col xs={12} className="p-0">
                            <ActivationTutorialStep infoImgSrc={'assets/images/step-1.svg'}>
                              <p style={{ fontSize: "1.2rem" }}>Deschideți o aplicație Office (Word, Excel, Powerpoint, etc.)</p>
                            </ActivationTutorialStep>
                          </Col>
                        </Row>
                        <Row className="mb-2" style={{ position: 'relative' }}>
                          <div className="hide-on-md" style={{ position: 'relative', width: '100%', textAlign: 'left', position: 'absolute', right: '3%', top: '50%', zIndex: '9999' }}>
                            <img src="/assets/images/CoreSoftware Group.svg" style={{ width: '3.5rem' }}></img>
                          </div>
                          <Col xs={12} className="p-0">
                            <ActivationTutorialStep infoImgSrc={'assets/images/step-2.svg'}>
                              <p style={{ fontSize: "1.2rem" }}>La prima deschidere a aplicației vă este cerut să introduceți licența achiziționată. Introduceți licența și apăsați pe butonul next (următorul).</p>
                              <p style={{ fontSize: "0.8rem" }}><i>{"*În cazul în care fereastra de activare nu apare, navigați în stânga-sus la File/Fișier -> Account/Cont -> Activate product/Activare produs."}</i></p>
                            </ActivationTutorialStep>
                          </Col>
                        </Row>
                        <Row className="mb-2" style={{ position: 'relative' }}>
                          <div className="hide-on-md" style={{ position: 'relative', width: '100%', textAlign: 'left', position: 'absolute', right: '3%', top: '50%', zIndex: '9999' }}>
                            <img src="/assets/images/CoreSoftware Group.svg" style={{ width: '3.5rem' }}></img>
                          </div>
                          <Col xs={12} className="p-0">
                            <ActivationTutorialStep infoImgSrc={'assets/images/step-3.svg'} imgSrc={'assets/images/bifam-optiune.png'}>
                              <p style={{ fontSize: "1.2rem" }}>În momentul în care vă apare fereastra de activare, vom bifa a 2-a variantă, activare prin telefon, conform imaginii alăturate.</p>
                              <p style={{ fontSize: "0.8rem" }}><i>*click pe imagine pentru mărire</i></p>
                            </ActivationTutorialStep>
                          </Col>
                        </Row>
                        <Row className="mb-2" style={{ position: 'relative' }}>
                          <div className="hide-on-md" style={{ position: 'relative', width: '100%', textAlign: 'left', position: 'absolute', right: '5%', top: '70%', zIndex: '9999' }}>
                            <img src="/assets/images/Group.svg"></img>
                          </div>
                          <Col xs={12} className="p-0">
                            <ActivationTutorialStep infoImgSrc={'assets/images/step-4.svg'} imgSrc={'assets/images/id-install.png'}>
                              <p style={{ fontSize: "1.2rem" }}>În fereastra de activare ce vă apare în program, la pasul 2, veți găsi id-ul de instalare. Exemplu este imaginea alăturată. Copiați acest ID de la pasul 2 și îl introduceți în sistemul de activare, ce îl puteți găsi mai jos în această pagină.
                                ID-ul de la pasul 3 va fi transmis pe email în câteva minute.</p>
                              <p style={{ fontSize: "0.8rem" }}><i>*click pe imagine pentru mărire</i></p>
                            </ActivationTutorialStep>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                  <Container fluid className="mt-5 mb-5 text-center info-step">
                    <Row className="wrapper align-items-center mb-3">
                      <Col className="d-grid titleActivate gap-5">
                        <h1>SECȚIUNEA DE ACTIVARE</h1>
                        <p className="text-center">Te rugăm completează mai jos cu ID-ul de instalare: Introduceți câte un șir în fiecare chenar în ordinea afișată în fereastra de activare și apasă pe butonul "Obține ID-ul de confirmare".</p>
                        <ActivationForm />
                      </Col>
                    </Row>
                  </Container>
                </Container>
              </Col>
            </Row >
          </Container >
          <Row className="footer">
            <Col className="d-flex justify-content-center px-3" xl={6} md={12} sm={12}>
              <img
                src="/assets/images/Core-Software-Logo.png"
                alt="Core Software Logo"
                className="footerLogo"
              />
            </Col>
            <Col className="copyright" xl={6} md={12} sm={12}>
              Copyright &copy;
              {" CORE SOFTWARE SOLUTIONS SRL. Toate drepturile rezervate. "}
            </Col>
          </Row>
        </>
      )
      }

    </>
  );
}

export default Activation;
